.spaceClass {
  margin: 0 auto;
}

.spanName {
  font-size: 18px;
  margin: 0 auto;
}

.rowClass {
  margin-bottom: 12px;
}

.phoneCol {
  :global(.ant-input) {
    border-radius: .25rem;
  }
}

.codeCol {
  :global(.ant-input) {
    border-radius: .25rem 0 0 .25rem;
  }
}

.vCodeCol button {
  width: 100%;
  border-radius: 0 .25rem .25rem 0;
}

.loginButtonCol button {
  width: 100%;
  border-radius: .25rem;
}

.loginRow1 {
  padding-bottom: 100px
}

.loginRow2 {
  min-width: 400px;
  padding-bottom: 200px;
}

.tencentCaptchaRow{
  display:none;
}

/* 大屏幕 */
@media (min-width: 979px) {
  .loginRow1 {
  }

  .loginRow2 {
    display: none;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
  .loginRow1 {
  }

  .loginRow2 {
    display: none;
  }
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .loginRow1 {
    display: none;
  }

  .loginRow2 {
  }
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
  .loginRow1 {
    display: none;
  }

  .loginRow2 {
  }
}