.ordinary-class {

  .ant-page-header-heading-title{
    cursor:pointer
  }

  .ant-layout-header, .ant-page-header {
    background-color: transparent;
  }

  .ant-page-header {
    background-color: hsla(0, 0%, 100%, .4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  }

  .page-title {
    color: #61dafb;
  }

  .ant-page-header-heading-title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .ant-page-header-heading-extra{
    cursor:pointer;
  }

  .site-layout-background {
    min-height: calc(100vh - 64px - 30px);
    display: flex;
    align-items: stretch;
  }

  .user-name{
    font-weight: 500;
    font-style: initial;
  }

  .footer-name {
    height: 30px;
    width: 100%;
    text-align: center;
    background-color: hsla(0, 0%, 100%, .4);
    box-shadow: 0 -1px 2px rgba(0, 0, 0, .1);
    padding: 5px;
    margin-bottom: 5px;
    color: #909090;
    font-size: 12px;
  }


  /* 大屏幕 */
  @media (min-width: 979px) {
    .ant-page-header {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .ant-page-header-heading {
      width: 900px;
      margin: 0 auto;
    }
  }

  /* 平板电脑和小屏电脑之间的分辨率 */
  @media (min-width: 768px) and (max-width: 979px) {
    .ant-page-header {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  /* 横向放置的手机和竖向放置的平板之间的分辨率 */
  @media (max-width: 767px) {
    .ant-page-header {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  /* 横向放置的手机及分辨率更小的设备 */
  @media (max-width: 480px) {
    .ant-page-header {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}
