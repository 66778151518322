@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout {
  background-color: transparent;
}

.private-span {
  height: 100%;
  display: inline-block;
  padding: .25em .4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #fff;
  background-color: #dc3545;
}


.button-color-dust {
  background-color: #F5222D;
  border-color: #F5222D;

  &:hover, &:focus {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }

  &:active, &.active {
    background-color: #cf1322;
    border-color: #cf1322;
  }
}

.button-color-volcano {
  background-color: #FA541C;
  border-color: #FA541C;

  &:hover, &:focus {
    background-color: #ff7a45;
    border-color: #ff7a45;
  }

  &:active, &.active {
    background-color: #d4380d;
    border-color: #d4380d;
  }
}

.button-color-sunset {
  background-color: #FAAD14;
  border-color: #FAAD14;

  &:hover, &:focus {
    background-color: #ffc53d;
    border-color: #ffc53d;
  }

  &:active, &.active {
    background-color: #d48806;
    border-color: #d48806;
  }
}

.button-color-cyan {
  background-color: #13C2C2;
  border-color: #13C2C2;

  &:hover, &:focus {
    background-color: #36cfc9;
    border-color: #36cfc9;
  }

  &:active, &.active {
    background-color: #08979c;
    border-color: #08979c;
  }
}

.button-color-green {
  background-color: #52C41A;
  border-color: #52C41A;

  &:hover, &:focus {
    background-color: #73d13d;
    border-color: #73d13d;
  }

  &:active, &.active {
    background-color: #389e0d;
    border-color: #389e0d;
  }
}

.button-color-daybreak {
  background-color: #1890FF;
  border-color: #1890FF;

  &:hover, &:focus {
    background-color: #096dd9;
    border-color: #096dd9;
  }

  &:active, &.active {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
}

.button-color-geekblue {
  background-color: #2F54EB;
  border-color: #2F54EB;

  &:hover, &:focus {
    background-color: #1d39c4;
    border-color: #1d39c4;
  }

  &:active, &.active {
    background-color: #597ef7;
    border-color: #597ef7;
  }
}

.button-color-purple {
  background-color: #722ED1;
  border-color: #722ED1;

  &:hover, &:focus {
    background-color: #9254de;
    border-color: #9254de;
  }

  &:active, &.active {
    background-color: #531dab;
    border-color: #531dab;
  }
}

.dropdown-button-color-primary-red {
  button {
    //background-color: #F5222D;
    //border-color: #F5222D;

    &:hover, &:focus {
      //background-color: #ff4d4f;
      border-color: #ff4d4f;
      color: #ff4d4f;
    }

    &:active, &.active {
      //background-color: #cf1322;
      border-color: #cf1322;
      color: #ff4d4f;
    }
  }
}


.dropdown-button-color-primary-green {
  button {
    //background-color: #52C41A;
    //border-color: #52C41A;

    &:hover, &:focus {
      //background-color: #73d13d;
      border-color: #73d13d;
      color: #5b9c32;
    }

    &:active, &.active {
      //background-color: #389e0d;
      border-color: #389e0d;
      color: #5b9c32;
    }
  }
}


@color-green: #28a745;
@color-hover-green: #ffffff;
@active-background-green: rgb(36, 125, 56);
@active-border-green: #28a745;

.input-green {
  &::selection {
    background: @active-border-green;
  }

  &:hover {
    border-color: @active-border-green;
    box-shadow: 0 0 0.2em @active-border-green;
  }

  &:active {
    box-shadow: 0 0 0.4em @active-border-green;
  }

  &:focus {
    border-color: @active-border-green;
    box-shadow: 0 0 0.4em @active-border-green;
  }

}

.button-green {
  color: @color-green;
  background-color: transparent;
  background-image: none;
  border-color: @color-green;

  &:active {
    color: @color-hover-green;
    background-color: @active-background-green;
    border-color: @active-border-green;
  }

  &:hover {
    color: @color-hover-green;
    background-color: @color-green;
    border-color: @active-border-green;
    box-shadow: 0 0 0.2em @active-border-green;
  }

  &:disabled {
    color: @color-green;
    background-color: transparent;
  }

  &:focus {
    color: @color-hover-green;
    background-color: @active-background-green;
    border-color: @active-border-green;
  }
}

.button-white-green {
  color: @color-hover-green;
  background-color: @color-green;
  background-image: none;
  border-color: @color-green;

  &:hover {
    color: @color-hover-green;
    background-color: @active-background-green;
    border-color: @active-border-green;
  }

  &:disabled {
    color: @color-green;
    background-color: transparent;
  }

  &:focus {
    color: @color-hover-green;
    background-color: @active-background-green;
    border-color: @active-border-green;
  }
}

@color-blue: #30b4cb;
@color-hover-blue: #ffffff;
@active-background-blue: #238192;
@active-border-blue: #30b4cb;

.button-blue {
  color: @color-hover-blue;
  background-color: @color-blue;
  background-image: none;
  border-color: @color-blue;

  &:hover {
    color: @color-hover-blue;
    background-color: @active-background-blue;
    border-color: @active-border-blue;
  }

  &:disabled {
    color: @color-blue;
    background-color: transparent;
  }

  &:focus {
    color: @color-hover-blue;
    background-color: @active-background-blue;
    border-color: @active-border-blue;
  }
}

@ant-radio-red-color: red;
@ant-radio-red-shadow-color: rgba(255, 0, 0, 0.08);

.ant-radio-red {

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid @ant-radio-red-color;
  }

  .ant-radio:hover .ant-radio-inner {
    border: 1px solid @ant-radio-red-color;
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: @ant-radio-red-color;
  }

  .ant-radio-input:focus .ant-radio-inner {
    box-shadow: 0 0 0 3px @ant-radio-red-shadow-color
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus .ant-radio-inner {
    border-color: @ant-radio-red-color;
  }

  .ant-radio-checked::after {
    border: 1px solid @ant-radio-red-color;
  }

  .ant-radio-wrapper-checked .ant-radio-inner {
    box-shadow: 0 0 0 3px @ant-radio-red-shadow-color
  }

}

@ant-radio-green-color: #06c106;
@ant-radio-green-shadow-color: rgba(6, 193, 6, 0.08);

.ant-radio-green {

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid @ant-radio-green-color;
  }

  .ant-radio:hover .ant-radio-inner {
    border: 1px solid @ant-radio-green-color;
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: @ant-radio-green-color;
  }

  .ant-radio-input:focus .ant-radio-inner {
    box-shadow: 0 0 0 3px @ant-radio-green-shadow-color
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus .ant-radio-inner {
    border-color: @ant-radio-green-color;
  }

  .ant-radio-checked::after {
    border: 1px solid @ant-radio-green-color;
  }

  .ant-radio-wrapper-checked .ant-radio-inner {
    box-shadow: 0 0 0 3px @ant-radio-green-shadow-color
  }

}


.ant-modal-confirm-btns {
  .ant-btn {
    &:hover, &:active, &:focus {
      border-color: #389e0d;
      color: #5b9c32;
    }
  }

  .ant-btn-dangerous {
    &:hover, &:active {
      border-color: #fd7978;
      color: #fd7978;
    }

    &:focus {
      border-color: #ff4d4f;
      color: #ff4d4f;
    }
  }
}
@primary-color: #00bee7;