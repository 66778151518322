.spaceClass {
  width: 100%;

}

.spinDiv {
  width: 100vw;
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: hsla(0, 0%, 100%, 0.66);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.searchRow {
  margin-bottom: 10px;
}

.labelRow {
  margin-bottom: 10px;
}


.labelSpace {
  width: 100%;
  height: 100%;
  margin-bottom:.25rem;

  :global(label) {
    cursor: pointer
  }
}

.noteCol {

  cursor: pointer;

  .dropdownDivider {
    height: 0;
    margin: .7rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }

  .title {
    font-size: 1rem;
    margin: .25rem 0;
  }

  .private {
    font-size: 4px;
    margin-left: 4px;
  }

  .markdown {
    color: #8a8a8a;
    position: relative;
    line-height: 1.5em;
    height: 7.5em;
    overflow: hidden;
    vertical-align: top;
    text-align: left;
  }

}

.imageCol {
  margin: 30px;

  :global(.ant-image-img) {
    border-radius: 50%;
  }
}

.affix {
  float: right;
}

.noteListRow1 input, .noteListRow2 input, .noteListRow1 button, .noteListRow2 button {
  border-radius: .25rem;
}

.noteListRow1, .noteListRow2 {
  width: 100%;
}

.noteListRow1 {
  margin: 0 auto;
}

.noteListRow2 {
  padding: 0 20px;
}

.tooltip {
  :global(.ant-tooltip-inner) {
    border-radius: 7px;
  }
}

$color: #28a745;

.pagination {
  :global(.ant-pagination-item a:hover) {
    color: $color
  }

  :global(.ant-pagination-item:hover){
    border-color: $color;
  }

  //:global(.ant-pagination-item-link:hover){
  //  border-color: $color;
  //}
  //
  //:global(.ant-pagination-item-link svg:hover) {
  //  color: $color
  //}


  :global(.ant-pagination-item-active) {
    border-color: $color;

    :global(a) {
      color: $color
    }
  }
}

.pageNormal {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pageSmall {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* 大屏幕 */
@media (min-width: 979px) {
  .noteListRow2 {
    display: none;
  }
  .pageSmall {
    display: none;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
  .noteListRow2 {
    display: none;
  }
  .pageSmall {
    display: none;
  }
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .noteListRow1 {
    display: none;
  }
  .pageNormal {
    display: none;
  }
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
  .noteListRow1 {
    display: none;
  }
  .pageNormal {
    display: none;
  }
}