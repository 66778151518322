$color: #28a745;
$color-hover: #ffffff;
$active-background: rgb(36, 125, 56);
$active-border: #28a745;

.spaceClass {
  width: 100%;

  :global(.ant-space-item) {
    //width: 100%;
  }
}

.spinDiv {
  width: 100vw;
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: hsla(0, 0%, 100%, 0.66);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.row {
  min-height: 0.5rem;
}

.title {
  text-align: center;
}

.input {
  width: 100%;

  &::selection {
    background: $active-border;
  }

  &:hover {
    border-color: $active-border;
    box-shadow: 0 0 0.2em $active-border;
  }

  &:active {
    box-shadow: 0 0 0.4em $active-border;
  }
}

.labelSpace {
  width: 100%;
  height: 100%;
  padding-bottom: 4px;

  :global(label) {
    cursor: pointer
  }

}

.tooltip {
  :global(.ant-tooltip-inner) {
    border-radius: 7px;
  }
}

.labelRowSmall {
  margin-top: 20px;
  min-height: 30px;
}

.labelRowMedium {
  margin-top: 20px;
}


.isPrivateRow {
  margin-top: 20px;
}

.colIsPrivate {
  text-align: left;
}

.addButton {
}

/* 大屏幕 */
@media (min-width: 979px) {
  .labelRowSmall {
    display: none;
  }
  .addButton {
    width: 96%;
    margin-left: 4%;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
  .labelRowSmall {
    display: none;
  }
  .addButton {
    width: 96%;
    margin-left: 4%;
  }
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .labelRowMedium {
    display: none;
  }
  .addButton {
    width: 100%;
  }
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
  .labelRowMedium {
    display: none;
  }
  .addButton {
    width: 100%;
  }
}

//.button1 {
//  color: $color;
//  background-color: transparent;
//  background-image: none;
//  border-color: $color;
//
//  &:active {
//    color: $color-hover;
//    background-color: $active-background;
//    border-color: $active-border;
//  }
//
//  &:hover {
//    color: $color-hover;
//    background-color: $color;
//    border-color: $active-border;
//    box-shadow: 0 0 0.2em $active-border;
//  }
//
//  &:disabled {
//    color: $color;
//    background-color: transparent;
//  }
//
//  &:focus {
//    color: $color-hover;
//    background-color: $active-background;
//    border-color: $active-border;
//  }
//}

.vditor {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.viewButton {
  margin-left: 20px;
}

.buttonRow {
  margin-top: 20px;
  margin-bottom: 20px;
}