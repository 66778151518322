
.spaceClass {
  width: 100%;

  :global(.ant-space-item) {
    //width: 100%;
  }
}

.spinDiv {
  width: 100vw;
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: hsla(0, 0%, 100%, 0.66);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.row {
  min-height: 0.5rem;
  margin-bottom: 20px;
}

.title {
  text-align: center;
}

