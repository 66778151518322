.spaceClass {
  margin: 0 auto;
}

.imageClass {
  margin: 0 auto;
}

.spinDiv {
  margin: 0 auto;
}

.title{
  padding-top: 20px;
  padding-bottom: 20px;
}

.errorSpan {
  font-size: 1.5rem;
}

.errorSpan404 {
  font-size: 3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-weight: 500;
}

/* 大屏幕 */
@media (min-width: 979px) {
  .errorSpan {
    font-size: 1.5rem;
  }

  .errorSpan404 {
    font-size: 3rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
  .errorSpan {
    font-size: 1.5rem;
  }

  .errorSpan404 {
    font-size: 3rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .errorSpan {
    font-size: 1rem;
  }

  .errorSpan404 {
    font-size: 1.5rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
  .errorSpan {
    font-size: 1rem;
  }

  .errorSpan404 {
    font-size: 1.5rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
}