
.spaceClass {
  width: 100%;

  :global(.ant-space-item) {
    //width: 100%;
  }
}

.spinDiv {
  width: 100vw;
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px 10px 40px 10px;
  background-color: hsla(0, 0%, 100%, 0.66);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.private {
  font-size: 4px;
  margin-left: 4px;
}

.imageCol {
  width: 50px;
  margin-right: 15px;

  :global(.ant-image-img) {
    border-radius: 50%;
  }
}

.row {
  margin-top: 20px;
}

.rowAuther{
  margin-bottom: 20px;
}

.rowLabel{
  margin-top: 5px;
  margin-bottom: 5px;
}

.autherName, .createDate {
  text-align: left;
}

.autherNameA {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #232323;

  &::selection {
    color: black;
  }

  &:hover {
    color: black;
  }

  &:active {
    color: black;
  }

}

.timeSpan {
  font-size: 12px;
  color: #969696;
  font-weight: 500;
}

.noteButton {
  text-align: right;
}

.vditorDiv {
  display: none;
}

.html {
  text-align: left;
}



/* 大屏幕 */
@media (min-width: 979px) {
  .content {
    margin: 20px auto;
    padding: 20px 10px 40px 10px;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
  .content {
    margin: 20px auto;
    padding: 20px 10px 40px 10px;
  }
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .content {
    margin: 0 auto 20px auto;
    padding: 0 10px 40px 10px;
  }
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
  .content {
    margin: 0 auto 20px auto;
    padding: 0 10px 40px 10px;
  }
}